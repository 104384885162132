import Layout from "../../components/layout"
import Heading from "../../components/heading"
import HorizontalImage from "../../components/horizontal-image"
import Text from "../../components/text"
import VerticalImage from "../../components/vertical-image"
import VerticalText from "../../components/vertical-text"
import React from 'react';
import { graphql } from "gatsby"

export const query = graphql`
  query {
    photo1: file(relativePath: { eq: "vstup-vytah/portal-1.jpg" }) { ...fluidImage }
    photo2: file(relativePath: { eq: "vstup-vytah/portal-2.jpg" }) { ...fluidImage }
    photo3: file(relativePath: { eq: "vstup-vytah/portal-3.jpg" }) { ...fluidImage }
    photo4: file(relativePath: { eq: "vstup-vytah/portal-4.jpg" }) { ...fluidImage }
    photo5: file(relativePath: { eq: "vstup-vytah/portal-5.jpg" }) { ...fluidImage }
  }
`

const VstupVytahPage = ({ data }) => (
  <Layout title="Vstup výtah">
    <HorizontalImage marginTop borderBottom image={data.photo1.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo2.childImageSharp.fluid} />
    <VerticalText>
      <Heading>Vstup k výtahu, Praha 7, 2018</Heading>
      <Text>
        Původní tiskařské haly byly zásobovány nákladním výtahem ze dvora. Po ukončení činnosti tiskárny se haly pronajaly jako kancelářské prostory. Nákladní výtah byl proto nahrazen osobním, a tím vznikl nový vstup do budovy. Původně byl před výtahem krytý vstupní prostor a vedle něj dvě krytá garážová stání. Tím, že nákladní výtah sloužil pouze k zásobování, neměl vstup k němu patřičnou důstojnost a přehlednost. Mou snahou bylo prostory upravit tak, aby se zachovalo co nejvíce původních konstrukcí, včetně základů. Obě garáže jsem diagonálně seřízl tak, aby se otevřel pohled na výtah přímo při vstupu z průjezdu do dvora. Druhá garáž byla zkrácena a upravena na kolárnu oddělenou cihlovou perforovanou stěnou. Strop nad vstupem k výtahu byl zvýšen. Tím se opticky zvýraznil oproti vstupům do garáže a kolárny. Vznikl tak portál viditelný při vstupu z průjezdu, jež je zachován po celé své výšce. Průchod portálem ze dvora je snížený a doplněný o stejnou cihlovou perforovanou stěnu, chránící vstup proti povětrnostním vlivům. Svislé i vodorovné konstrukce jsou opatřeny betonovou stěrkou v barvě pálených cihel. Obě střechy (nižší nad garáží a kolárnou a vyšší nad vstupním portálem) jsou osazeny zelení. U vstupu k výtahu je zavěšená černá tabule s názvy firem, pod kterou je umístěna dřevěná lavička.
      </Text>
    </VerticalText>
    <HorizontalImage marginTop borderBottom borderTop image={data.photo3.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo4.childImageSharp.fluid} />
    <VerticalImage image={data.photo5.childImageSharp.fluid} />
  </Layout>
)

export default VstupVytahPage
